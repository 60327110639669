import { NavigationItem } from './common/header/components/navigation/shared/types'

export const navItems: NavigationItem[] = [
  {
    label: 'Довірчі послуги',
    url: '',
    items: [
      {
        label: 'Придбати сертифікат',
        url: '',
      },
      {
        label: 'Оновити сертифікат',
        url: '',
      },
      {
        label: 'Знайти сертифікат',
        url: '',
      },
      {
        label: 'Підписати документ',
        url: '',
      },
      {
        label: 'Перевірити підпис',
        url: '',
      },
    ],
  },
  {
    label: 'Захищені носії',
    url: '',
    items: [
      {
        label: '«Алмаз-1К» (Пластмасовий корпус)',
        url: '/devices/0',
      },
      {
        label: '«Алмаз-1К» (Металевий корпус)',
        url: '/devices/1',
      },
      {
        label: '«Алмаз-1К» (Bluetooth-пристрій)',
        url: '/devices/2',
      },
      {
        label: '«Кристал-1»',
        url: '/devices/3',
      },
      {
        label: '«Securetoken-337К»',
        url: '/devices/4',
      },
      {
        label: '«Securetoken-338M»',
        url: '/devices/5',
      },
      {
        label: '«Securetoken-338S»',
        url: '/devices/6',
      },
      {
        label: '«KP-382»',
        url: '/devices/7',
      },
    ],
  },
  {
    label: 'Стати представництвом',
    url: '/partners',
  },
  {
    label: 'Новини',
    url: '/news',
  },
  {
    label: 'Пункти обслуговування',
    url: '',
  },
  {
    label: 'Дія ID | Дія. Підпис',
    url: '',
    items: [
      {
        label: 'Про Дія. Підпис',
        url: '/',
      },
      {
        label: 'Договір про надання кваліфікованих електронних довірчих послуг в межах проекту Дія. Підпис',
        url: '',
      },
      {
        label: 'Заява про приєднання до договору про надання кваліфікованих електронних довірчих послуг',
        url: '',
      },
      {
        label: 'Договір приєднання про підключення інформаційно-комунікаційної системи до Порталу Дія в межах проєкту Дія ID',
        url: '',
      },
      {
        label: 'Договір приєднання про підключення інформаційно-комунікаційної системи до Порталу Дія в межах проєкту Дія ID (Двомовний)',
        url: '',
      },
    ],
  },
  {
    label: 'Ще',
    url: '',
    items: [
      {
        label: 'Програмне забезпечення',
        url: '',
      },
      {
        label: 'Регламент та нормативні акти',
        url: '',
      },
      {
        label: 'Захищені носії ключів',
        url: '',
      },
      {
        label: 'Питання та відповіді',
        url: '',
      },
      {
        label: 'Де працює підпис Дії',
        url: '',
      },
      {
        label: 'Форми договорів',
        url: '',
      },
      {
        label: 'Як працює електронний підпис?',
        url: '',
      },
    ],
  },
]
